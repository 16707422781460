import React, {FC, useEffect, useRef, useState} from 'react';
import './App.css';
import {
    Button,
    ButtonGroup,
    ClickAwayListener,
    createTheme,
    Grow,
    MenuItem,
    MenuList,
    Paper,
    Popper
} from "@mui/material";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import {ThemeProvider} from "@emotion/react";
import axios from "axios";
import fileDownload from 'js-file-download';
import {Email, House, RestartAlt} from '@mui/icons-material';

const theme = createTheme({
    palette: {
        mode: 'dark',
        background: {
            default: '#0f172a',
            paper: '#0f172a'
        },
        primary: {
            main: '#0f172a',
            dark: '#0f172a'
        }
    }
});

const slides = [
    '/slides/1-introduction.mp4',
    '/slides/2-problem.mp4',
    '/slides/3-personalisation.mp4',
    '/slides/4-features.mp4',
    '/slides/5-competition.mp4',
    '/slides/6-customer.mp4',
    '/slides/7-market.mp4',
    '/slides/8-traction.mp4',
    '/slides/9-identity.mp4',
    '/slides/10-inventory.mp4',
    '/slides/10-inventory.mp4',
    '/slides/11-proposition.mp4',
    '/slides/12-branding.mp4',
    '/slides/13-contact.mp4',
]

function App() {

    return (
      <ThemeProvider theme={theme}>

          <Home/>

      </ThemeProvider>
    );
}

const Home: FC = () => {

    const videoRef = useRef<HTMLVideoElement>(null);

    const [loading, setLoading] = useState<boolean>(true)

    const [slide, setSlide] = useState<number>(0)

    useEffect(() => {
        if (!videoRef.current) {
            return;
        }

        videoRef.current.defaultMuted = true;
        videoRef.current.muted = true;

        videoRef.current.play()
          .catch((reason) => {
              console.log(reason);
          });

    }, [videoRef, slide])

    return (
      <div className="home">
          <video
            ref={videoRef}
            src={slides[slide]}
            autoPlay
            playsInline
            muted
            className="slide"
            onLoadedData={() => setLoading(false)}
            onLoadStart={() => setLoading(true)}
          />

          {
              loading && (
                <div className="loading-container">
                    Loading
                    <RestartAlt className="loading-icon"/>
                </div>
              )
          }
          <div className="single-control-container">
              <Button variant="outlined" onClick={() => setSlide((n) => n - 1)} disabled={slide == 0}>
                  Prev
              </Button>
          </div>
          <div className="single-control-container">
              <Button variant="outlined" onClick={() => setSlide((n) => n + 1)}
                      disabled={slide == slides.length - 1}>
                  Next
              </Button>
          </div>

          <div className="controls-container">
              <div className="page-controls">
                  <Button variant="outlined" onClick={() => setSlide((n) => n - 1)} disabled={slide == 0}>
                      Prev
                  </Button>
                  <Button variant="outlined" sx={{marginLeft: '10px'}} onClick={() => setSlide((n) => n + 1)}
                          disabled={slide == slides.length - 1}>
                      Next
                  </Button>
              </div>

              <div className="other-controls">
                  <DownloadButton/>

                  <Button href="https://indivog.com" variant="outlined" sx={{marginLeft: '10px'}}>
                      <House/>
                  </Button>
                  <Button href="mailto:hello@indivog.com?subject=Let's Talk" variant="outlined" sx={{marginLeft: '10px'}}>
                      <Email/>
                  </Button>
              </div>

          </div>
      </div>
    )
}


const DownloadButton: FC = () => {

    const options = [
        {
            label: 'Download PDF',
            optionLabel: 'PDF',
            url: '/pitch/pitch.pdf',
            filename: 'Indivog.pdf'
        },
        {
            label: 'Download MP4',
            optionLabel: 'MP4',
            url: '/pitch/pitch.mp4',
            filename: 'Indivog.mp4'
        }
    ]

    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef<HTMLDivElement>(null);
    const [selectedIndex, setSelectedIndex] = React.useState(0);

    const handleClick = () => {
        axios.get(options[selectedIndex].url, {
            responseType: 'blob',
        })
          .then((res) => {
              fileDownload(res.data, options[selectedIndex].filename)
          })
    };

    const handleMenuItemClick = (
      event: React.MouseEvent<HTMLLIElement, MouseEvent>,
      index: number,
    ) => {
        setSelectedIndex(index);
        setOpen(false);
    };

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event: Event) => {
        if (
          anchorRef.current &&
          anchorRef.current.contains(event.target as HTMLElement)
        ) {
            return;
        }

        setOpen(false);
    };

    return (
      <>
          <ButtonGroup variant="outlined" ref={anchorRef}>
              <Button onClick={handleClick}>{options[selectedIndex].label}</Button>
              <Button
                size="small"
                aria-controls={open ? 'split-button-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-label="select merge strategy"
                aria-haspopup="menu"
                onClick={handleToggle}
              >
                  <ArrowDropDownIcon/>
              </Button>
          </ButtonGroup>
          <Popper
            sx={{
                zIndex: 1,
            }}
            open={open}
            anchorEl={anchorRef.current}
            role={undefined}
            transition
            disablePortal
          >
              {({TransitionProps, placement}) => (
                <Grow
                  {...TransitionProps}
                  style={{
                      transformOrigin:
                        placement === 'bottom' ? 'center top' : 'center bottom',
                  }}
                >
                    <Paper>
                        <ClickAwayListener onClickAway={handleClose}>
                            <MenuList id="split-button-menu" autoFocusItem>
                                {options.map((option, index) => (
                                  <MenuItem
                                    key={index}
                                    selected={index === selectedIndex}
                                    onClick={(event) => handleMenuItemClick(event, index)}
                                  >
                                      {option.optionLabel}
                                  </MenuItem>
                                ))}
                            </MenuList>
                        </ClickAwayListener>
                    </Paper>
                </Grow>
              )}
          </Popper>
      </>
    )
}

export default App;
